import React from 'react';
import Counting from './Counting'; // Import the Counting component
import { useEffect } from 'react';
import data from '../../seo/seo.json'

const AboutUs = () => {
  const stats = [
    { number: 5000, label: 'Happy Clients' },
    { number: 8, label: 'Awards Winning' },
    { number: 15, label: 'Perfect Specialists' },
  ];

  useEffect(() => {

    document.title = data?.aboutUsPageTitle ;
    document.querySelector('meta[name="description"]').setAttribute("content", data?.aboutUsPageDescription);
  }, []);



  return (
    <div className="text-[#4a3f35] font-sans" >
      <div className="container mx-auto px-4 py-8 max-w-5xl">
        <div className=" text-white rounded-3xl p-8 md:p-10 mb-10 text-center" style={{ background: 'rgba(85, 56, 51, 1)' }}>
          <h2 className="text-2xl md:text-3xl mb-6 font-[Bulter]">Our History</h2>
          <p className="mb-4 text-sm md:text-base font-[Poppins]">
            FEEL THE WELLNESS was started single-handedly in the year 2001 by Mr. Bhavin with the motive of Recognition which was the first-ever concept of health weight & spa under one roof. The same rule continued to bring for lifestyle transformation in order to make an effective presence and development in the field of Beauty and Wellness.
          </p>
          <p className="text-sm md:text-base font-[Poppins]">
            Giving excellent service in the beauty and wellness industry for the last 17+ years, we convinced that it was time for the entry for an international collaboration and pampering and hence we decorated the current brand name FEEL. By the year 2023, we are Planning for more expansions in the same industry all over Gujarat. With our past records, we can assure, that we provide the highest level of customer service to each client, thereby establishing a long-lasting relationship of trust and mutual dedication.
          </p>
        </div>
        
        <div className="flex flex-wrap justify-around text-center font-[Poppins]">
          {stats.map((stat, index) => (
            <div key={index} className="w-full sm:w-1/3 mb-8">
              <Counting end={stat.number} duration={1000} /> {/* Use Counting component here */}
              <div className="text-lg">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
