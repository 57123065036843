import React, { useEffect, useState } from "react";

import feelWhite from "../../assets/images/feel-logo-white.png";
import Loader from "../loader/Loader";
import data from "../../seo/seo.json";

const Gallery = () => {
  const [galleryData, setGalleryData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getGalleryData = async () => {
    setLoading(true);
    let url = `https://api.feelthewellness.in/feelapp/gallery-image/`;

    try {
      let res = await fetch(url);

      if (res.ok) {
        let data = await res.json();
        setGalleryData(data);
      } else {
        console.log("Error in response");
      }
    } catch (error) {
      console.log("Error in fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGalleryData();

    document.title = data?.galleryPageTitle;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", data?.galleryPageDescription); 

  }, []);

return  loading ? (
    <Loader />
  ) : (
    <div>
      <h2 className=" text-[42px] md:text-[82px] lg:text-[96px] text-[#553833] py-6 text-center md:text-left md:pl-24 lg:pl-32 font-[luxyna] uppercase">
        Gallery
      </h2>
      <div className=" bg-[#553933] p-5 md:px-6 lg:py-8 lg:px-12 relative">
        <div className=" lg:w-[75%]  ml-auto grid md:grid-cols-3 md:grid-rows-2 gap-5 md:gap-6 lg:gap-8 aspect-[16/8]">
          <div className=" bg-white w-full ">
            <img
              src={galleryData?.[0]?.image}
              alt=""
              className="w-full h-full object-cover "
            />
          </div>
          <div className=" bg-white w-full  md:row-span-2">
            <img
              src={galleryData?.[1]?.image}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className=" bg-white w-full md:row-span-2 md:-mt-[100px]">
            <img
              src={galleryData?.[2]?.image}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className=" bg-white w-full ">
            <img
              src={galleryData?.[3]?.image}
              alt=""
              className="w-full h-full object-cover "
            />
          </div>
        </div>
        <div className=" lg:w-[75%]  ml-auto grid md:grid-cols-3 gap-5 md:gap-6 mt-5 md:mt-6 lg:gap-8">
          {galleryData?.slice(4, galleryData?.length).map((item, index) => (
            <div key={index} className=" bg-white w-full">
              <img
                src={item.image}
                alt=""
                className="w-full h-full object-cover aspect-square"
              />
            </div>
          ))}
        </div>
        <div className=" w-1/5 absolute top-[25vh] left-10 hidden lg:block">
          <img src={feelWhite} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
