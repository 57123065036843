import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../AuthContext";
import './cart.css'
import data from "../../seo/seo.json";

import appointImg from "../../assets/images/appointment-bg.jpg";

const Cart = () => {
  const { cartCount, setCartCount } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    date: "",
    comment: "",
    exist: "no",
    name: "",
    email: "",
    first_name: "",
    last_name: "",
    gender: "M",
    phoneNumber: "",
    dob: "",
    aniversary: "",
  });

  const [bookedServiceData, setBookedServiceData] = useState([]);

  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    localStorage.getItem("bookedServiceData") &&
      setBookedServiceData(
        JSON.parse(localStorage.getItem("bookedServiceData"))
      );

    document.title = data?.cartPageTitle;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", data?.cartPageDescription);

  }, []);

  const handleClickAWT = async () => {
    let payload = {};

    setSubmitLoading(true);

    if (!formData.phoneNumber) {
      toast.error("Please enter your mobile number");
      setSubmitLoading(false);
      return;
    }

    payload = {
      mobile_number: formData.phoneNumber,
      appointment_date: formData.date.split("T")[0],
      service_ids: bookedServiceData.map((item) => item.servid),
      is_register: true,
      expectedStartTime: formData.date?.split("T")[1]?.split(":")?.join(""),
      comment: formData.comment,
    };

    try {
      let resp = await fetch("https://api.feelthewellness.in/feelapp/bookingawt/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (resp.ok) {
        let data = await resp.json();
        console.log(data);
        setFormData({
          date: "",
          comment: "",
          exist: "yes",
          name: "",
          email: "",
          first_name: "",
          last_name: "",
          gender: "M",
          phoneNumber: "",
          dob: "",
          aniversary: "",
        });
        setBookedServiceData([]);
        localStorage.setItem("bookedServiceData", JSON.stringify([]));
        setCartCount(0);

        toast.success("Appointment booked successfully");
      } else if (resp.status === 400) {
        toast.error("Please enter valid data");
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleNewClient = async () => {
    let payload = {};

    setSubmitLoading(true);

    if (!formData.phoneNumber) {
      toast.error("Please enter your mobile number");
      setSubmitLoading(false);
      return;
    }

    if (!formData.phoneNumber) {
      toast.error("Please enter your mobile number");
      setSubmitLoading(false);
      return;
    }

    let url = `https://api.feelthewellness.in/feelapp/bookingac/`;

    payload = {
      mobile_number: formData.phoneNumber,
      email: formData.email,
      clientId: formData.phoneNumber,
      first_name: formData.first_name,
      last_name: formData.last_name,
      birth_date: formData.dob,
      anniversary_date: formData.aniversary,
      gender: formData.gender,
      appointment_date: formData.date.split("T")[0],
      category: "Regular",
      referral_type: "website",
    };

    try {
      let resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (resp.ok) {
        let data = await resp.json();
        handleClickAWT();
      } else if (resp.status === 400) {
        toast.error("Please enter valid data");
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className=" bg-[#553833] py-3 lg:p-5">
        <div className=" bg-white rounded-none lg:rounded-lg min-h-60 lg:mx-8 my-10 py-4 px-2 lg:px-4 overflow-x-auto">
          <table className=" w-full">
            <thead className=" ">
              <tr className="  border-b-2 border-[#553833]">
                <th className=" grow shrink min-w-36 pb-4 ">Service name</th>
                <th className=" w-[12%] min-w-36 pb-4">Price</th>
                <th className=" w-[12%] min-w-36 pb-4">QTY</th>
                <th className=" w-[12%] min-w-36 pb-4">Subtotal</th>
                <th className=" w-[12%] min-w-36 pb-4">Action</th>
              </tr>
            </thead>
            <tbody>
              {bookedServiceData && bookedServiceData.length > 0 ? (
                bookedServiceData.map((item, i) => (
                  <tr className="">
                    <td className=" py-2 text-center">{item?.service_name}</td>

                    <td className=" py-2 text-center">&#8377;{item?.price}</td>
                    <td className=" py-2 text-center">1</td>
                    <td className=" py-2 text-center">
                      &#8377;
                      {item?.price}
                    </td>
                    <td className=" py-2 text-center">
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          let bookedServiceDataCopy = [...bookedServiceData];
                          bookedServiceDataCopy.splice(i, 1);
                          setBookedServiceData(bookedServiceDataCopy);
                          localStorage.setItem(
                            "bookedServiceData",
                            JSON.stringify(bookedServiceDataCopy)
                          );
                          setCartCount(
                            localStorage.getItem("bookedServiceData")
                              ? JSON.parse(
                                  localStorage.getItem("bookedServiceData")
                                ).length
                              : 0
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={"5"} className=" text-center py-6">
                    No item in cart
                  </td>
                </tr>
              )}

              {bookedServiceData && bookedServiceData.length > 0 && (
                <tr>
                  <td colSpan={"2"} className=" py-7 text-center"></td>
                  <td className=" py-7 text-center">Total Amount :</td>
                  <td className=" py-7 text-center ">
                    &#8377;
                    {bookedServiceData.reduce(
                      (acc, item) => acc + parseInt(item.price),
                      0
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className=" bg-white flex">
        <div className=" w-full lg:w-[70%] flex flex-col items-center lg:p-8">
          <h3 className=" font-[luxyna] font-semibold text-center text-2xl lg:text-3xl text-[#553833] mt-5">
            Make An Appointment
          </h3>
          <div className=" text-center text-[#553833] mt-5 font-[poppins] text-sm lg:text-base">
            We will contact you with a free consultation and our experts provide
            <br />
            full information to help you find the best option.
          </div>
          <div className=" flex flex-col lg:flex-row gap-2 lg:gap-10 w-full max-w-[640px] px-4 lg:px-0">
            <div className=" flex flex-col gap-4 w-full mt-5">
              <div className=" flex flex-col gap-2 w-full">
                <label htmlFor="name" className=" text-sm font-normal pl-2">
                  *Registered Mobile Number
                </label>
                <input
                  type="number"
                  id="name"
                  name="name"
                  required
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      phoneNumber: e.target.value,
                    })
                  }
                  placeholder="mobile number"
                  className=" bg-[#F1F1F1] rounded-md px-3 py-2 text-sm font-[poppins] w-full"
                />
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="date" className=" text-sm font-normal pl-2">
                  *Appointment Date
                </label>
                <input
                  type={"datetime-local"}
                  id="date"
                  name="date"
                  required
                  value={formData.date}
                  onChange={(e) =>
                    setFormData({ ...formData, date: e.target.value })
                  }
                  className=" bg-[#F1F1F1] rounded-md px-3 py-2 text-sm font-[poppins] w-full"
                  style={{
                    color: formData.date ? "black" : "#A0A0A0",
                  }}
                  placeholder="Appointment Date"
                />
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="comment" className=" text-sm font-normal pl-2">
                  Comment
                </label>
                <input
                  type="text"
                  id="comment"
                  name="comment"
                  placeholder="Write your comment here"
                  value={formData.comment}
                  onChange={(e) =>
                    setFormData({ ...formData, comment: e.target.value })
                  }
                  className=" bg-[#F1F1F1] rounded-md px-3 py-2 text-sm font-[poppins] w-full"
                />
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="exist" className=" text-sm font-normal pl-2">
                  Are you existing client?
                </label>
                <div className="flex gap-2 pl-2 ">
                  <input
                    type="radio"
                    name="exist"
                    id="yes"
                    checked={formData.exist === "yes"}
                    onChange={(e) => setFormData({ ...formData, exist: "yes" })}
                    className="cursor-pointer"
                  />
                  <label htmlFor="yes" className="cursor-pointer">
                    Yes
                  </label>
                  <input
                    type="radio"
                    name="exist"
                    id="no"
                    checked={formData.exist === "no"}
                    onChange={(e) => setFormData({ ...formData, exist: "no" })}
                    className="cursor-pointer"
                  />
                  <label htmlFor="no" className="cursor-pointer">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div
              className={`flex flex-col gap-4 w-full mt-5 ${
                formData.exist === "yes" ? " invisible hidden lg:flex" : "visible"
              }`}
            >
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="email" className=" text-sm font-normal pl-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className=" bg-[#F1F1F1] rounded-md px-3 py-2 text-sm font-[poppins] w-full"
                />
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label
                  htmlFor="first-name"
                  className=" text-sm font-normal pl-2"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="first-name"
                  name="first-name"
                  placeholder="Enter your first name"
                  value={formData.first_name}
                  onChange={(e) =>
                    setFormData({ ...formData, first_name: e.target.value })
                  }
                  className=" bg-[#F1F1F1] rounded-md px-3 py-2 text-sm font-[poppins] w-full"
                />
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label
                  htmlFor="last-name"
                  className=" text-sm font-normal pl-2"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="last-name"
                  name="last-name"
                  placeholder="Enter your last name"
                  value={formData.last_name}
                  onChange={(e) =>
                    setFormData({ ...formData, last_name: e.target.value })
                  }
                  className=" bg-[#F1F1F1] rounded-md px-3 py-2 text-sm font-[poppins] w-full"
                />
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="dob" className=" text-sm font-normal pl-2">
                  Date of Birth
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={(e) =>
                    setFormData({ ...formData, dob: e.target.value })
                  }
                  style={{
                    color: formData.dob ? "black" : "#A0A0A0",
                  }}
                  className=" bg-[#F1F1F1] rounded-md px-3 py-2 text-sm font-[poppins] w-full"
                />
              </div>

              <div className="flex flex-col gap-2 w-full">
                <label
                  htmlFor="aniversary"
                  className=" text-sm font-normal pl-2"
                >
                  Aniversary
                </label>
                <input
                  type="date"
                  id="aniversary"
                  name="aniversary"
                  value={formData.aniversary}
                  onChange={(e) =>
                    setFormData({ ...formData, aniversary: e.target.value })
                  }
                  style={{
                    color: formData.aniversary ? "black" : "#A0A0A0",
                  }}
                  className=" bg-[#F1F1F1] rounded-md px-3 py-2 text-sm font-[poppins] w-full"
                />
              </div>
            </div>
          </div>
          <div className=" mt-5">
            <div
              className=" mx-auto w-fit"
              onClick={() => {
                if (formData.exist === "yes") {
                  handleClickAWT();
                } else {
                  handleNewClient();
                }
              }}
            >
              <div className=" bg-[#553833] text-white px-6 cursor-pointer py-2 rounded-md justify-center items-center flex ">
                {submitLoading ? "Submitting..." : "Book Appointment"}
                {submitLoading && (
                  <div class="submit-loader h-5 w-5 ml-3"></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" w-[30%] hidden lg:block">
          <img
            src={appointImg}
            alt="appointment"
            className=" w-full h-full object-cover"
          />
        </div>
      </div>

      <div
        className="flex flex-col justify-center items-center mt-5 p-6 sm:p-8 lg:p-10 gap-4"
        style={{ background: "rgba(85, 56, 51, 1)", fontWeight: "300" }}
      >
        <p className="font-[LUXYNA] text-white text-center text-sm sm:text-base lg:text-lg max-w-full">
          We know how to unbrace all the muscles of your body, relieve back and
          joint pains and improve your well-being.
        </p>
      </div>
    </>
  );
};

export default Cart;
